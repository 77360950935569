import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import { isEmpty } from 'lodash';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import toast from 'react-hot-toast';
import config from '../app.config';
import { DATE_FORMAT, HEARD_ABOUT_US, MEMBERSHIP_ACTION_NAMES, MEMBERSHIP_TYPES, TIMEZONES } from './constants';
import { Link } from 'react-admin';

export const getFormattedDate = (date, format = DATE_FORMAT.MONTH_DAY_YEAR) => {
  if (date) {
    const d = new Date(date);
    const year = d.getFullYear().toString();
    const y = year.charAt(2) + year.charAt(3);
    const month = (1 + d.getMonth()).toString().padStart(2, '0');
    const day = d.getDate().toString().padStart(2, '0');

    let formattedDate;
    if (format === DATE_FORMAT.MONTH_DAY_YEAR) {
      formattedDate = `${month}/${day}/${y}`;
    } else if (format === DATE_FORMAT.YEAR_MONTH_DAY) {
      formattedDate = `${year}-${month}-${day}`;
    } else if (format === DATE_FORMAT.DAY_MONTH_YEAR) {
      formattedDate = `${day}-${month}-${year}`;
    }
    return formattedDate;
  }
};

function timeConvert(time) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? 'am' : 'pm'; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(''); // return adjusted time or original string
}

// returns format 03/30/21 5:00pm to 6:00pm
export const getAppointmentDateTime = (start_at, end_at, timezone) => {
  const dateFormat = 'YYYY-MM-DDTHH:mm';
  if (start_at && timezone) {
    const formattedStartDate = momentTimezone
      .utc(start_at)
      .tz(timezone)
      .format(dateFormat);
    const formattedEndDate = momentTimezone
      .utc(end_at)
      .tz(timezone)
      .format(dateFormat);
    const formattedDate = getFormattedDate(formattedStartDate);
    const dateTime = `${formattedDate} ${timeConvert(
      formattedStartDate.substr(11, 5),
    )} to ${timeConvert(formattedEndDate.substr(11, 5))}`;
    return dateTime;
  }
};
export const getFormattedDate_T = (date, timezone, dateFormat = 'YYYY-MM-DDTHH:mm') => {
  if (date) {
    const formattedStartDate = timezone
      ? momentTimezone.utc(date).tz(timezone).format(dateFormat)
      : momentTimezone.utc(date).format(dateFormat);
    const formattedDate = getFormattedDate(formattedStartDate);
    return formattedDate;
  }
};

export const getUtcToLocalDateTime = (date, formatTime = true) => {
  const dateFormat = 'YYYY-MM-DDTHH:mm';
  if (date) {
    const utcDate = moment.utc(date).toDate();
    const local = moment(utcDate).local().format(dateFormat);
    const formattedDate = getFormattedDate(local);
    const formattedTime = timeConvert(local.substr(11, 5));
    return formatTime ? `${formattedDate} ${formattedTime}` : formattedDate; //04/13/21 10:22pm
  }
};

//returns format 03/21/21 2:45pm
export const getFormattedDateTime = (start_at, timezone) => {
  const dateFormat = 'YYYY-MM-DDTHH:mm';
  if (start_at) {
    const formattedStartDate = timezone
      ? momentTimezone.utc(start_at).tz(timezone).format(dateFormat)
      : momentTimezone.utc(start_at).format(dateFormat);
    const formattedDate = getFormattedDate(formattedStartDate);
    const dateTime = `${formattedDate} ${timeConvert(
      formattedStartDate.substr(11, 5),
    )}`;
    return dateTime;
  }
};

// This date time format is used in API payload
export const getDateTime = (date, dateTime = true) => {
  if (date) {
    const d = new Date(date);
    const year = d.getFullYear().toString();
    const month = (1 + d.getMonth()).toString().padStart(2, '0');
    const day = d.getDate().toString().padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    const time = d.toString().split(' ')[4];
    return dateTime ? `${formattedDate} ${time}` : formattedDate;
  }
};

export const getHours = dateTime => {
  return dateTime.substr(11, 5);
};

export function arrayToMap(ary) {
  return ary.reduce((m, o) => {
    const map = m;
    map[o.title] = o;
    return map;
  }, {});
}

export function arrayToMapFocus(array, optionMap) {
  return array.reduce((m, o) => {
    const map = m;
    map[optionMap[o.key].apiKey] = o.value;
    return map;
  }, {});
}

export function arrayToMapPressure(array, optionMap) {
  return array.reduce((m, o) => {
    const map = m;
    map[optionMap[o.key].apiPKey] = o.value;
    return map;
  }, {});
}

export function preferenceMap(customerPref) {
  return customerPref.reduce((m, o) => {
    // eslint-disable-next-line no-param-reassign
    m[o.key] = o;
    return m;
  }, {});
}

export function getPressureMap(state, optionsMap) {
  if (isEmpty(state.userOptions)) {
    return {};
  }
  const userOptions = state.userPressureOptions;
  const ary = userOptions.map(o => {
    const val = {};
    val[optionsMap[o.key].key] = o.value;
    return val;
  });
  return ary.reduce((m, o) => {
    const map = m;
    const key = Object.keys(o)[0];
    map[key] = o[key];
    return map;
  }, {});
}

export const removeEmptyOrNull = obj => {
  Object.keys(obj).forEach(key => {
    if (key === 'percussion_therapy' && !obj[key]) return obj[key];
    else (obj[key] === null || obj[key] === undefined) && delete obj[key];
  });
  return obj;
};

export const isToday = date => {
  return momentTimezone.utc(date).isSame(momentTimezone.utc(), 'day');
};

export const showToast = (message, type = 'success') => {
  toast[type](message);
};

export const isAdmin = role => role === 'admin';
export const isTherapist = role => role === 'therapist';
export const isBreakroom = role => role === 'breakroom';
export const isFrontDesk = role => role === 'front_desk';
export const isGss = role => role === 'gss';
export const isShopManager = role => role === 'shop_manager';
export const isOperatingPartner = role => role === 'operating-partner';
export const isSuperAdmin = role => role === 'super-admin';

export const NoTitle = () => <span style={{ display: 'none' }}>Post</span>;

export const getParseFilters = obj => {
  const result = Object.keys(obj).reduce((acc, prev) => {
    if (obj[prev] === 0 || obj[prev]) {
      acc.push(
        `${prev}=${Array.isArray(obj[prev])
          ? encodeURIComponent(obj[prev].join(','))
          : encodeURIComponent(obj[prev])
        }`,
      );
    }
    return acc;
  }, []);
  return result.join('&');
};

export const getFormattedCurrency = (amount, entity_type) => {
  if (entity_type) {
    return amount >= 0 ? `-$${Math.abs(amount)}` : `$${Math.abs(amount)}`;
  }
  return amount === 0 || amount
    ? amount >= 0
      ? `$${amount}`
      : `-$${Math.abs(amount)}`
    : '';
};

export const formatPhoneNumber = str => {
  // Check if country code is passed by user
  if (str === undefined || str === null) {
    return;
  }

  str =
    str.charAt(0) === '+' && str.charAt(1) === '1'
      ? str.substring(2, str.length)
      : str;

  //Filter only numbers from the input
  let cleaned = ('' + str).replace(/\D/g, '');
  //Check if the input is of correct length
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
};

export const isDayBirthday = (dob, timezone, date) =>
  date
    ? dob.substr(5) === momentTimezone.tz(`${date}Z`, timezone).format('MM-DD')
    : dob.substr(5) === momentTimezone.tz(timezone).format('MM-DD');

export const getStarRatings = rating => {
  if (rating >= '0.10' && rating <= '0.9') {
    return (
      <div className="ratting-wrap">
        <StarHalfIcon style={{ color: '#F2A434' }} />
        <StarBorderIcon style={{ color: '#F2A434' }} />
        <StarBorderIcon style={{ color: '#F2A434' }} />
        <StarBorderIcon style={{ color: '#F2A434' }} />
        <StarBorderIcon style={{ color: '#F2A434' }} />
      </div>
    );
  }
  if (rating >= '0.90' && rating <= '1') {
    return (
      <div className="ratting-wrap">
        <StarIcon style={{ color: '#F2A434' }} />
        <StarBorderIcon style={{ color: '#F2A434' }} />
        <StarBorderIcon style={{ color: '#F2A434' }} />
        <StarBorderIcon style={{ color: '#F2A434' }} />
        <StarBorderIcon style={{ color: '#F2A434' }} />
      </div>
    );
  }
  if (rating >= '1.10' && rating <= '1.9') {
    return (
      <div className="ratting-wrap">
        <StarIcon style={{ color: '#F2A434' }} />
        <StarHalfIcon style={{ color: '#F2A434' }} />
        <StarBorderIcon style={{ color: '#F2A434' }} />
        <StarBorderIcon style={{ color: '#F2A434' }} />
        <StarBorderIcon style={{ color: '#F2A434' }} />
      </div>
    );
  }
  if (rating >= '1.90' && rating <= '2') {
    return (
      <div className="ratting-wrap">
        <StarIcon style={{ color: '#F2A434' }} />{' '}
        <StarIcon style={{ color: '#F2A434' }} />
        <StarBorderIcon style={{ color: '#F2A434' }} />
        <StarBorderIcon style={{ color: '#F2A434' }} />
        <StarBorderIcon style={{ color: '#F2A434' }} />
      </div>
    );
  }
  if (rating >= '2.10' && rating <= '2.9') {
    return (
      <div className="ratting-wrap">
        <StarIcon style={{ color: '#F2A434' }} />{' '}
        <StarIcon style={{ color: '#F2A434' }} />
        <StarHalfIcon style={{ color: '#F2A434' }} />
        <StarBorderIcon style={{ color: '#F2A434' }} />
        <StarBorderIcon style={{ color: '#F2A434' }} />
      </div>
    );
  }
  if (rating >= '2.90' && rating <= '3') {
    return (
      <div className="ratting-wrap">
        <StarIcon style={{ color: '#F2A434' }} />{' '}
        <StarIcon style={{ color: '#F2A434' }} />{' '}
        <StarIcon style={{ color: '#F2A434' }} />
        <StarBorderIcon style={{ color: '#F2A434' }} />
        <StarBorderIcon style={{ color: '#F2A434' }} />
      </div>
    );
  }
  if (rating >= '3.10' && rating <= '3.9') {
    return (
      <div className="ratting-wrap">
        <StarIcon style={{ color: '#F2A434' }} />
        <StarIcon style={{ color: '#F2A434' }} />{' '}
        <StarIcon style={{ color: '#F2A434' }} />
        <StarHalfIcon style={{ color: '#F2A434' }} />
        <StarBorderIcon style={{ color: '#F2A434' }} />
      </div>
    );
  }
  if (rating >= '3.90' && rating <= '4') {
    return (
      <div className="ratting-wrap">
        <StarIcon style={{ color: '#F2A434' }} />
        <StarIcon style={{ color: '#F2A434' }} />
        <StarIcon style={{ color: '#F2A434' }} />
        <StarIcon style={{ color: '#F2A434' }} />
        <StarBorderIcon style={{ color: '#F2A434' }} />
      </div>
    );
  }
  if (rating >= '4.10' && rating <= '4.9') {
    return (
      <div className="ratting-wrap">
        <StarIcon style={{ color: '#F2A434' }} />
        <StarIcon style={{ color: '#F2A434' }} />
        <StarIcon style={{ color: '#F2A434' }} />
        <StarIcon style={{ color: '#F2A434' }} />
        <StarHalfIcon style={{ color: '#F2A434' }} />
      </div>
    );
  }
  if (rating >= '4.90' && rating <= '5') {
    return (
      <div className="ratting-wrap">
        <StarIcon style={{ color: '#F2A434' }} />
        <StarIcon style={{ color: '#F2A434' }} />
        <StarIcon style={{ color: '#F2A434' }} />
        <StarIcon style={{ color: '#F2A434' }} />
        <StarIcon style={{ color: '#F2A434' }} />
      </div>
    );
  }
  if (rating === null || rating === '0') {
    return (
      <div className="ratting-wrap">
        <StarBorderIcon style={{ color: '#F2A434' }} />
        <StarBorderIcon style={{ color: '#F2A434' }} />
        <StarBorderIcon style={{ color: '#F2A434' }} />
        <StarBorderIcon style={{ color: '#F2A434' }} />
        <StarBorderIcon style={{ color: '#F2A434' }} />
      </div>
    );
  }
};

export const getHeardUsValue = user => {
  let value = '';
  if (user?.heard_about_us) {
    const _heardUs = HEARD_ABOUT_US.find(i => i.id === user?.heard_about_us);
    value = _heardUs ? user?.heard_about_us : 'Other';
  }
  return value;
};

export const getHeardUsOtherValue = user => {
  let value = '';
  if (user?.heard_about_us) {
    const _heardUs = HEARD_ABOUT_US.find(i => i.id === user?.heard_about_us);
    value = _heardUs ? '' : user?.heard_about_us;
  }
  return value;
};

export const getLocations = locations => {
  return locations?.map(loc => ({
    id: loc.location_id,
    name: loc.location.name,
  }));
};

export const mapLocationReaderId = urlName => {
  return config[urlName];
};

export const getUTCDate = date => {
  const _date = moment.utc(date).format('YYYY-MM-DDTHH:mm');
  return _date;
};

export const getFiltersDate = (report, dateFormat = 'YYYY-MM-DD') => {
  // const date = getFormattedDate(new Date(), DATE_FORMAT.YEAR_MONTH_DAY);

  const last_month = moment().subtract(1, 'months').format(dateFormat);
  const current_date = moment().format(dateFormat);
  if (
    ['ratings', 'tips', 'cancelMember', 'scoreCard', 'distribution', 'crossLocationAdjustment', 'members'].includes(
      report,
    )
  )
    return { dateLower: last_month, dateUpper: current_date };
  if (report === 'promo')
    return {
      txnDateLower: last_month,
      txnDateUpper: current_date,
    };
  if (report === 'gifts')
    return {
      redeemedAtLower: last_month,
      redeemedAtUpper: current_date,
    };
};

export const getReportsDateFormat = date => {
  const dateFormat = 'YYYY-MM-DD HH:mm:ss';

  return date
    ? momentTimezone.tz(date, TIMEZONES.LA).utc().format(dateFormat)
    : '';
};

export const getCcAmount = transactions => {
  const sum = transactions.reduce((sum, obj) => sum + obj.cc_value, 0);
  const final_value = sum >= 0 ? 0 : getFormattedCurrency(Math.abs(sum));
  return final_value;
};

export const getSbAmount = transactions => {
  const sum = transactions.reduce((sum, obj) => sum + obj.sb_value, 0);
  const final_value = sum >= 0 ? 0 : getFormattedCurrency(Math.abs(sum));
  return final_value;
};

export const getLocationName = locationId => {
  const workLocations = JSON.parse(localStorage.getItem('userWorkLocations'));
  const location =
    workLocations && workLocations.find(loc => loc.location_id === locationId);
  return location && location.location.name;
};

export const getEmployeesLocationName = workLocations => {
  if (workLocations) {
    const locationsName = workLocations.map(loc => loc.location.name);
    return locationsName.join(', ');
  }
  return null;
};

export const getPlanId = planId => {
  return planId === '50minv2' ? '50min' : '80min';
};

export const getRoles = (role, USER_ROLES) => {
  if (['admin'].includes(role)) return USER_ROLES;
  else if (['operating-partner'].includes(role)) {
    const role_s = USER_ROLES.filter(
      item =>
        item.id === 'therapist' ||
        item.id === 'front_desk' ||
        item.id === 'shop_manager' ||
        item.id === 'breakroom',
    );
    return role_s;
  } else if (['shop_manager'].includes(role)) {
    const role_s = USER_ROLES.filter(
      item =>
        item.id === 'therapist' ||
        item.id === 'front_desk' ||
        item.id === 'breakroom',
    );
    return role_s;
  }
};

export const getLocationUrlName = locationId => {
  const workLocations = JSON.parse(localStorage.getItem('userWorkLocations'));
  const location =
    workLocations && workLocations.find(loc => loc.location_id === locationId);
  return location && location.location.url_name;
};

export const ALLOWED_ORIGINS = [
  'https://qa2.squeezemassage.com/',
  'https://stg.squeezemassage.com/',
  'https://www.squeezemassage.com/',
  'https://squeeze-web-git-s20-1260-squeeze-massage.vercel.app/',
];

export const getMembershipTypeName =
  (membershipType) => (MEMBERSHIP_TYPES.find((membership) => membershipType === membership?.id)?.name);

export const getMembershipActionName =
  (membershipType) => (MEMBERSHIP_ACTION_NAMES.find((membership) => membershipType === membership?.id)?.name);

export const isFoundingMember = membership =>
  ( !isEmpty( membership?.campaign ) && membership?.campaign_id ) ? true : false;

 export const getMembershipStatus = membership => {
    return membership?.status === 'Awaiting'
      ? `F${membership?.membership_type}`
      : membership?.membership_type;
  };

  export const renderGuestName = userDetails => (
    <Link className="underline" to={`/guest/${userDetails.user_id || userDetails.guestId}`}>
      {userDetails.guest_name}
    </Link>
  );