import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import React from 'react';
import { Loading, Title, useQuery } from 'react-admin';
import { useParams } from 'react-router-dom';
import { getLocations } from '../utils';

const PromoDetails = () => {
  let { promoId } = useParams();
  const {
    data: promoDetails,
    loading: loadPromoDetails,
    // error: promoDetailsError,
    // refetch: refetchPromoDetails,
  } = useQuery({
    type: 'getOne',
    resource: 'promo',
    payload: { id: promoId },
  });

  return (
    <React.Fragment>
      <Title title="Promo Details" />
      <CardContent>
        {loadPromoDetails && <Loading />}
        {!loadPromoDetails && (
          <div className="details-display">
            <div>
              <PromoCodeDetailsCard {...promoDetails} />
            </div>
          </div>
        )}
      </CardContent>
    </React.Fragment>
  );
};

const PromoCodeDetailsCard = props => {
  const workLocations = JSON.parse(localStorage.getItem('userWorkLocations'));
  return (
    <Card className="h-full w-full p-5 mt-6">
      <div id="promo-code" className="mb-5">
        <div className="text-gray-400">Promo Code</div>
        <div>{props.code}</div>
      </div>
      <div id="promo-start-date" className="mb-5">
        <div className="text-gray-400">Start Date</div>
        <div>
          {props.promo_start_date
            ? props.promo_start_date
            : 'Start Date Not Set'}
        </div>
      </div>
      <div id="promo-end-date" className="mb-5">
        <div className="text-gray-400">End Date</div>
        <div>
          {props.promo_end_date ? props.promo_end_date : 'End Date Not Set'}
        </div>
      </div>
      <div id="promo-capacity" className="mb-5">
        <div className="text-gray-400">Capacity</div>
        <div>{props.capacity ? props.capacity : 'Capacity Not Set'}</div>
      </div>
      { props?.discount_percentage > 0 ? (<div id="promo-discount-percent" className="mb-5">
        <div className="text-gray-400">Discount Percentage</div>
        <div>
          {`${props.discount_percentage}%`}
        </div>
      </div> ): (<div id="promo-discount-percent" className="mb-5">
        <div className="text-gray-400">Discount Amount</div>
        <div>
          {props.discount_amount}
        </div>
      </div>)}
      <div id="promo-products" className="mb-5">
        <div className="text-gray-400">Products</div>
        <div>
          {props.products && props.products.length > 0
            ? props.products.map((product, i) => (
                <span key={i}>
                  {i > 0 && ', '}
                  <span className="capitalize">{product}</span>
                </span>
              ))
            : 'No Products Selected'}
        </div>
      </div>
      <div id="promo-restrictions" className="mb-5">
        <div className="text-gray-400">Restrictions</div>
        <div>
          {props.restrictions && props.restrictions.length > 0
            ? props.restrictions.map((restriction, i) => (
                <span key={i}>
                  {i > 0 && ', '}
                  <span className="capitalize">{restriction}</span>
                </span>
              ))
            : 'No Restrcitions Selected'}
        </div>
      </div>
      <div id="promo-locations" className="mb-5">
        <div className="text-gray-400">Locations</div>
        <div>
          {workLocations
            ? getLocations(workLocations).map((location, i) => (
                <span key={i}>
                  {i > 0 && ', '}
                  <span className="capitalize">{location.name}</span>
                </span>
              ))
            : 'No Locations Selected'}
        </div>
      </div>
      <div id="promo-notes" className="mb-5">
        <div className="text-gray-400">Notes</div>
        <div>{props.notes ? props.notes : 'No notes to display!'}</div>
      </div>
      <div id="promo-min-git-amount" className="mb-5">
        <div className="text-gray-400">Minimum Gift Amount</div>
        <div>
          {props.minimum_gift_amount
            ? props.minimum_gift_amount
            : 'No Minimum Gift Amount Set'}
        </div>
      </div>
      <div id="promo-appt-day-of-week">
        <div className="text-gray-400">Appointment Days of Week</div>
        <div>
          {props.appt_day_of_week && props.appt_day_of_week.length > 0
            ? props.appt_day_of_week.map((day, i) => (
                <span key={i}>
                  {i > 0 && ', '}
                  <span className="capitalize">{day}</span>
                </span>
              ))
            : 'No Appointment Days Selected'}
        </div>
      </div>
      <div id="promo-min-git-amount" className="mb-5">
        <div className="text-gray-400"># Of Membership Months to Discount</div>
        <div>
          {props.maximum_usage_per_user
            ? props.maximum_usage_per_user
            : 'No Membership Months to Discount Set'}
        </div>
      </div>
    </Card>
  );
};

export default PromoDetails;
