import React, { useState } from 'react';
import {
  ListContextProvider,
  Datagrid,
  FunctionField,
  useQuery,
  Loading,
  Pagination,
} from 'react-admin';
import { keyBy } from 'lodash';
import { getFormattedDate_T } from '../utils';

const CrossLocationAdjustmentReport = ({ filters, otherFilters }) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);

  const { data, total, loading } = useQuery({
    type: 'getList',
    resource: 'crossLocationAdjustment-report',
    payload: {
      filters: { ...filters, ...otherFilters },
      pagination: { page, perPage },
    },
  });

  const PostPagination = props => (
    <Pagination rowsPerPageOptions={[50]} {...props} />
  );
  if (loading) return <Loading />;

  return (
    <>
      <b>Membership Cross-Location Adjustment Report</b>

      <ListContextProvider
        value={{
          resource: 'report',
          basePath: '/report',
          data: keyBy(data, 'id'),
          ids: data && data.map(({ id }) => id),
          currentSort: { field: 'id', order: 'ASC' },
          selectedIds: [],
          total,
          page,
          setPage,
          perPage,
          setPerPage,
        }}
        pagination={<PostPagination />}
        perPage={perPage}
      >
        <Datagrid>
          <FunctionField
            label="Guest Email Address"
            render={record => record.user_email}
          />
          <FunctionField
            label="Guest Home Location"
            render={record => record.user_home_location}
          />
          <FunctionField
            label="Appointment Location"
            render={record => record.appointment_location}
          />
          <FunctionField
            label="Appointment Date"
            render={record => getFormattedDate_T(record.appointment_date)}
          />
          <FunctionField
            label="Adjustment Amount"
            render={record => record.adjustment_amount}
          />
        </Datagrid>
        <PostPagination
          page={page}
          perPage={perPage}
          setPage={setPage}
          total={total}
        />
      </ListContextProvider>
    </>
  );
};
export default CrossLocationAdjustmentReport;
