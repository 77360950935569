const config = {
  apiBase: process.env.REACT_APP_API_BASE,
  // Reader Location Ids
  studiocity: process.env.REACT_APP_LOCATION_ID_STUDIO_CITY,
  brentwood: process.env.REACT_APP_LOCATION_ID_BRENTWOOD,
  scottsdale: process.env.REACT_APP_LOCATION_ID_SCOTTSDALE,
  cherrycreek: process.env.REACT_APP_LOCATION_ID_CHERRYCREEK,
  rogers: process.env.REACT_APP_LOCATION_ID_ROGERS,
  alamoheights: process.env.REACT_APP_LOCATION_ID_ALAMOHEIGHTS,
  midtownatl: process.env.REACT_APP_LOCATION_ID_MIDTOWN_ATL,
  huntsville: process.env.REACT_APP_LOCATION_ID_HUNTSVILLE,
  northbrook: process.env.REACT_APP_LOCATION_ID_NORTHBROOK,
  lagunaniguel: process.env.REACT_APP_LOCATION_ID_LAGUNANIGUL,
  fortworth: process.env.REACT_APP_LOCATION_ID_FORTWORTH,
  sanantonio: process.env.REACT_APP_LOCATION_ID_SANANTONIO,
  providence: process.env.REACT_APP_LOCATION_ID_PROVIDENCE,
  boston: process.env.REACT_APP_LOCATION_ID_BOSTON,

  // Default Readers
  studiocityDefaultReader: process.env.REACT_APP_STUIO_CITY_DEFAULT_READER,
  brentwoodDefaultReader: process.env.REACT_APP_BRENTWOOD_DEFAULT_READER,
  scottsdaleDefaultReader: process.env.REACT_APP_SCOTTSDALE_DEFAULT_READER,
  cherrycreekDefaultReader: process.env.REACT_APP_CHERRY_CREEK_DEFAULT_READER,
  rogersDefaultReader: process.env.REACT_APP_ROGERS_DEFAULT_READER,
  alamoheightsDefaultReader: process.env.REACT_APP_ALAMO_HEIGHTS_DEFAULT_READER,
  midtownatlDefaultReader: process.env.REACT_APP_MIDTOWN_ATL_DEFAULT_READER,
  huntsvilleDefaultReader: process.env.REACT_APP_HUNTSVILLE_DEFAULT_READER,
  northbrookDefaultReader: process.env.REACT_APP_NORTHBROOK_DEFAULT_READER,
  lagunaniguelDefaultReader: process.env.REACT_APP_LAGUNANIGUL_DEFAULT_READER,
  fortworthDefaultReader: process.env.REACT_APP_FORTWORTH_DEFAULT_READER,
  sanantonioDefaultReader: process.env.REACT_APP_SANANTONIO_DEFAULT_READER,
  providenceDefaultReader: process.env.REACT_APP_PROVIDENCE_DEFAULT_READER,
  bostonDefaultReader: process.env.REACT_APP_BOSTON_DEFAULT_READER,
};

export default config;
