import React from 'react';
import {
  Datagrid,
  Filter,
  FunctionField,
  List,
  SearchInput,
  TextField
} from 'react-admin';
import { formatPhoneNumber } from '../utils/index';

const UserFilter = props => (
  <Filter {...props} className="searchWrapper">
    <SearchInput source="email" placeholder="Email" alwaysOn autoFocus/>
    <SearchInput source="name" placeholder="Name" alwaysOn/>
    <SearchInput source="phone" placeholder="Phone" alwaysOn/>
  </Filter>
);

export const UserList = ({ permissions, ...props }) => {
  return (
    <>
      <List
        filters={<UserFilter />}
        {...props}
        perPage={25}
        bulkActionButtons={false}
        className="main-table"
        exporter={false}
        empty={<UserFilter />}
      >
        <Datagrid rowClick="edit">
          <TextField source="first_name" />
          <TextField source="last_name" />
          <TextField source="email" />
          <FunctionField
            label="Phone"
            source="phone"
            sortable={false}
            render={record => formatPhoneNumber(record.phone)}
          />
        </Datagrid>
      </List>
    </>
  );
};
