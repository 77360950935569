/* eslint-disable import/no-anonymous-default-export */
import { showToast } from '../utils';
import { constructUrl } from '../utils/api';
import { isEmpty } from 'lodash';

const { client } = require('../services/api-client');

const getGift = async (_resource, params) => {
  try {
    const { id } = params;
    const _url = `gifts?id=${id}`;

    const resp = await client(_url, { authNeeded: true });
    const giftItem = resp?.items && resp.items[0];
    return {
      data: giftItem,
      total: resp.count,
    };
  } catch (err) {
    showToast(err.message, 'error');
    return Promise.reject(err);
  }
};

const getGiftList = async (_resource, params) => {
  try {
    let { pagination, filter } = params;

    if (isEmpty(filter)) {
      return { data: [], total: 0 };
    }

    const url = constructUrl('gifts', {
      filters: filter,
      pagination,
    });

    const resp = await client(url, { authNeeded: true });
    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    showToast(err.message, 'error');
    return Promise.reject(err);
  }
};

const getShipQueueList = async (_resource, params) => {
  try {
    let { pagination } = params;
    const { page, perPage } = pagination;
    const _offset = page === 1 ? 0 : (page - 1) * perPage;
    let _url = 'ship-queue';
    _url = _url.concat(
      `?offset=${_offset}&limit=${perPage}&fields=giftcarddeliveries.*,sender.*,giftcarddeliveries.service.duration`,
    );

    const resp = await client(_url, { authNeeded: true });
    return {
      data: resp.items,
      total: resp.count,
    };
  } catch (err) {
    showToast(err.message, 'error');
    return Promise.reject(err);
  }
};

const shipGift = async (id, params) => {
  try {
    let url = constructUrl(`ship-giftcard/${id}`, {
      pagination: false,
    });
    const resp = await client(url, {
      authNeeded: true,
      method: 'PUT',
      data: params,
    });

    return {
      data: resp,
    };
  } catch (err) {
    showToast(err.message, 'error');
    return Promise.reject(err);
  }
};

const updateGift = async (_resource, params) => {
  const { code } = params;
  const url = constructUrl('gift/refund', { pagination: false });
  try {
    const resp = await client(url, {
      authNeeded: true,
      method: 'POST',
      data: { code },
    });
    return { data: resp };
  } catch (err) {
    showToast(err, 'error');
    return Promise.reject(err);
  }
};

const redeemGift = async (_resource, params) => {
  const { id, status, location_id } = params;
  const url = constructUrl(`gift/${id}`, { pagination: false });
  try {
    const resp = await client(url, {
      authNeeded: true,
      method: 'PUT',
      data: { status, location_id },
    });
    return { data: resp };
  } catch (err) {
    showToast(err.message, 'error');
    return Promise.reject(err);
  }
};

const purchaseGift = async (_resource, params) => {
  const { payload } = params;
  const url = constructUrl('gift/purchase', { pagination: false });
  try {
    const resp = await client(url, {
      authNeeded: true,
      method: 'POST',
      data: payload,
    });
    return { data: resp };
  } catch (err) {
    showToast(err, 'error');
    return Promise.reject(err);
  }
};

const fetchGiftPaymentIntent = async (amount, location_id) => {
  try {
    const url = 'gifts/payment-intent';
    const resp = await client(url, {
      authNeeded: true,
      data: { amount, location_id },
    });
    return resp;
  } catch (err) {
    return Promise.reject(err);
  }
};

export {
  getGift,
  getGiftList,
  getShipQueueList,
  shipGift,
  updateGift,
  purchaseGift,
  redeemGift,
  fetchGiftPaymentIntent,
};
