import React from 'react';
import {
  Datagrid,
  ListContextProvider,
  TextField,
  useQuery,
  Pagination,
  FunctionField,
  Loading,
} from 'react-admin';
import { keyBy } from 'lodash';
import { APPOINTMENT_REFUND, GUESTS, TEAM_MEMBERS } from '../utils/constants';
import { getAppointmentDateTime, getCcAmount, getSbAmount } from '../utils';
import { Refund } from './refund';
import history from '../history';
import { useParams } from 'react-router-dom';

export const UserCompletedAppointment = ({ card, ...props }) => {
  const [showRefund, setShowRefund] = React.useState(false);
  const [appointment, setAppointment] = React.useState(null);
  const { therapistId } = useParams();

  const filters =
    card === GUESTS ? { user_id: props.id } : { therapist_id: therapistId };

  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);

  const { data, total, refetch, loading } = useQuery({
    type: 'getList',
    resource: 'appointment',
    payload: {
      pagination: { page, perPage },
      filters: { ...filters, status: 'completed', order_by: 'desc' },
    },
  });
  if (loading) return <Loading />;

  // React.useEffect(() => {
  //   fetchAppointments();
  // }, []);

  // const fetchAppointments = () => {
  //   customDataProvider
  //     .getList('appointment', {
  //       pagination: { page, perPage },
  //       filters: { ...filters, status: 'completed' },
  //     })
  //     .then(({ data, total }) => {
  //       setData(data);
  //       setTotal(total);
  //     });
  // };

  const PostPagination = props => (
    <Pagination rowsPerPageOptions={[10, 25]} {...props} />
  );

  const onRowClick = record => {
    const baseRoute = card === GUESTS ? '/guest' : '/employee';
    return history.push(
      `${baseRoute}/appointment/${record.id}/view-guest-details/${record.user_id}`,
    );
  };

  const handleRefundClick = record => {
    setAppointment(record);
    setShowRefund(true);
  };

  return (
    <>
      <Refund
        showRefund={showRefund}
        setShowRefund={setShowRefund}
        appointment={appointment}
        refetch={refetch}
        refundReasons={APPOINTMENT_REFUND}
      />
      <ListContextProvider
        value={{
          resource: 'appointment',
          basePath: `/guest/${props.id}`,
          data: keyBy(data, 'id'),
          ids: data && data.map(({ id }) => id),
          currentSort: { field: 'id', order: 'ASC' },
          selectedIds: [],
          total,
          page,
          perPage,
          setPerPage,
          setPage,
        }}
        // pagination={<PostPagination />}
        perPage={perPage}
      >
        <Datagrid>
          <FunctionField
            label="Date/Time"
            render={record =>
              getAppointmentDateTime(
                record?.start_at,
                record?.end_at,
                record?.timezone,
              )
            }
          />
          {card === GUESTS && (
            <TextField source="therapist.first_name" label="Therapist" />
          )}
          {card === TEAM_MEMBERS && (
            <TextField source="user.first_name" label="User" />
          )}
          <TextField source="location.name" label="Location" />
          <TextField source="service.label" label="Service Type" />
          <FunctionField
            label="Code"
            render={record =>
              record.coupon_applied ? record.coupon_applied.toUpperCase() : ''
            }
          />
          <FunctionField
            label="SBs Used"
            render={record =>
              record.transactions?.length
                ? getSbAmount(record.transactions)
                : ''
            }
          />
          <FunctionField
            label="CC Total"
            render={record =>
              record.transactions?.length
                ? getCcAmount(record.transactions)
                : ''
            }
          />
          <FunctionField
            render={record => {
              return (
                <button
                  className="button-blue"
                  onClick={() => {
                    onRowClick(record);
                  }}
                >
                  View
                </button>
              );
            }}
          />
          <FunctionField
            render={record => {
              return (
                <button
                  className="button-blue"
                  onClick={() => {
                    handleRefundClick(record);
                  }}
                >
                  Refund
                </button>
              );
            }}
          />
        </Datagrid>
        <PostPagination
          page={page}
          perPage={perPage}
          setPage={setPage}
          total={total}
        />
      </ListContextProvider>
    </>
  );
};
