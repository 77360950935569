import React, { useState } from 'react';
import {
  ListContextProvider,
  Datagrid,
  FunctionField,
  useQuery,
  Loading,
  Pagination,
} from 'react-admin';
import { keyBy, isEmpty } from 'lodash';
import { getFormattedDate_T, renderGuestName } from '../utils';

const PromosReport = ( { otherFilters } ) =>
{
  const codeData = !isEmpty( otherFilters?.code );
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const { data, total, loading } = useQuery({
    type: 'getList',
    resource: 'promos-report',
    payload: {
      filters: { ...otherFilters, csv: false },
    },
  }, {enabled: codeData});

  const PostPagination = props => (
    <Pagination rowsPerPageOptions={[100]} {...props} />
  );

  if (loading) return <Loading />;
  return (
    <>
      <b>Promos Report</b>

      <ListContextProvider
        value={{
          resource: 'report',
          basePath: '/report',
          data: keyBy(data, 'id'),
          ids: data && data.map(({ id }) => id),
          currentSort: { field: 'id', order: 'DES' },
          selectedIds: [],
          total,
          page,
          setPage,
          perPage,
          setPerPage,
        }}
        pagination={<PostPagination />}
        perPage={perPage}
      >
        <Datagrid>
          <FunctionField label="ID" render={record => record.guestId} sortable/>
          <FunctionField label="Guest" render={record => renderGuestName(record)}/>
          <FunctionField label="Code" render={record => record.code}/>
          <FunctionField label="Zip" render={record => record.zip || 'NA'} />
          <FunctionField
            label="Date Used"
            render={record =>
              getFormattedDate_T(record.date_used, 'MM/DD/YY') || 'NA'
            }
          />
          <FunctionField label="Used For" render={record => record.used_for} />
          <FunctionField label="Shop" render={record => record.shop} />
          <FunctionField
            label="Membership Status"
            render={record => record.status}
          />
          <FunctionField
            label="Completed Appts"
            render={record => record.completedAppointmnet}
          />
          <FunctionField
            label="Upcoming Appts"
            render={record => record.upcomingAppointmnet}
          />
        </Datagrid>
        {/* <PostPagination
          page={page}
          perPage={perPage}
          setPage={setPage}
          total={total}
        /> */}
      </ListContextProvider>
    </>
  );
};
export default PromosReport;
