export const SIM_READERS = [
  {
    "id": "tmr_FTVv6wWvMky6ok",
    "object": "terminal.reader",
    "action": null,
    "device_sw_version": "",
    "device_type": "simulated_wisepos_e",
    "ip_address": "0.0.0.0",
    "label": "CherryCreek Reader 2",
    "last_seen_at": 1698213118640,
    "livemode": false,
    "location": "tml_FJVUrglcNTxeYI",
    "metadata": {},
    "serial_number": "83c77bfa-b184-40c8-8f08-4f98b7c4f113",
    "status": "online"
  },
  {
    "id": "tmr_FTVvwQ0iXOKQcW",
    "object": "terminal.reader",
    "action": null,
    "device_sw_version": "",
    "device_type": "simulated_wisepos_e",
    "ip_address": "0.0.0.0",
    "label": "Dallas Reader 4",
    "last_seen_at": 1698213118640,
    "livemode": false,
    "location": "tml_E3mGhwwvTlSVOh",
    "metadata": {},
    "serial_number": "f7d392d4-89e5-4fa9-a2be-05fcb77a3cb9",
    "status": "online"
  },
  {
    "id": "tmr_FTVvuwrA1vRcKv",
    "object": "terminal.reader",
    "action": null,
    "device_sw_version": "",
    "device_type": "simulated_wisepos_e",
    "ip_address": "0.0.0.0",
    "label": "Dallas Reader 3",
    "last_seen_at": 1698213118640,
    "livemode": false,
    "location": "tml_E3mGhwwvTlSVOh",
    "metadata": {},
    "serial_number": "06859273-b8c4-4a7a-aa5e-bdce133735ba",
    "status": "online"
  },
  {
    "id": "tmr_FTVvtQbDb9yIr6",
    "object": "terminal.reader",
    "action": null,
    "device_sw_version": "",
    "device_type": "simulated_wisepos_e",
    "ip_address": "0.0.0.0",
    "label": "Dallas Reader 2",
    "last_seen_at": 1698213118640,
    "livemode": false,
    "location": "tml_E3mGhwwvTlSVOh",
    "metadata": {},
    "serial_number": "c4c0659c-82b6-4cb1-b814-5f3371d144a4",
    "status": "online"
  },
  {
    "id": "tmr_FTVvqgcJpviBIu",
    "object": "terminal.reader",
    "action": null,
    "device_sw_version": "",
    "device_type": "simulated_wisepos_e",
    "ip_address": "0.0.0.0",
    "label": "Bethesda Reader 3",
    "last_seen_at": 1698213118640,
    "livemode": false,
    "location": "tml_E3mGSgW31G6Kmy",
    "metadata": {},
    "serial_number": "431a035a-7bd5-40a6-8664-96704b36b4d5",
    "status": "online"
  },
  {
    "id": "tmr_FTVvnQFNOr4xhG",
    "object": "terminal.reader",
    "action": null,
    "device_sw_version": "",
    "device_type": "simulated_wisepos_e",
    "ip_address": "0.0.0.0",
    "label": "Bethesda Reader 5",
    "last_seen_at": 1698213118640,
    "livemode": false,
    "location": "tml_E3mFoQNWXbHPkc",
    "metadata": {},
    "serial_number": "650e8195-1490-4b4e-af2a-562bff81cb42",
    "status": "online"
  },
  {
    "id": "tmr_FTVvlQfTevk5x9",
    "object": "terminal.reader",
    "action": null,
    "device_sw_version": "",
    "device_type": "simulated_wisepos_e",
    "ip_address": "0.0.0.0",
    "label": "Bethesda Reader 4",
    "last_seen_at": 1698213118640,
    "livemode": false,
    "location": "tml_E3mFoQNWXbHPkc",
    "metadata": {},
    "serial_number": "957a5de0-8406-4dba-8559-b19f86bba239",
    "status": "online"
  },
  {
    "id": "tmr_FTVvhwIawHjh3j",
    "object": "terminal.reader",
    "action": null,
    "device_sw_version": "",
    "device_type": "simulated_wisepos_e",
    "ip_address": "0.0.0.0",
    "label": "Bethesda Reader 3",
    "last_seen_at": 1698213118640,
    "livemode": false,
    "location": "tml_E3mFoQNWXbHPkc",
    "metadata": {},
    "serial_number": "01463e44-87cf-4ba5-9622-4a11c8d49f05",
    "status": "online"
  },
  {
    "id": "tmr_FTRArwJvNcrJ27",
    "object": "terminal.reader",
    "action": null,
    "device_sw_version": "",
    "device_type": "simulated_wisepos_e",
    "ip_address": "0.0.0.0",
    "label": "Dallas Reader 1",
    "last_seen_at": 1698213118640,
    "livemode": false,
    "location": "tml_E3mGhwwvTlSVOh",
    "metadata": {},
    "serial_number": "26ccb2c0-b2d3-49ae-b0c4-735a4c98afee",
    "status": "online"
  },
  {
    "id": "tmr_FTRArgs7wME1EY",
    "object": "terminal.reader",
    "action": null,
    "device_sw_version": "",
    "device_type": "simulated_wisepos_e",
    "ip_address": "0.0.0.0",
    "label": "CherryCreek Reader 1",
    "last_seen_at": 1698213118640,
    "livemode": false,
    "location": "tml_FJVUrglcNTxeYI",
    "metadata": {},
    "serial_number": "3c30dffa-1741-4e20-a9a1-2968896fcba4",
    "status": "online"
  },
  {
    "id": "tmr_FTRApQ5r09FsGX",
    "object": "terminal.reader",
    "action": null,
    "device_sw_version": "",
    "device_type": "simulated_wisepos_e",
    "ip_address": "0.0.0.0",
    "label": "Studio City Reader 4",
    "last_seen_at": 1698213118640,
    "livemode": false,
    "location": "tml_E3mFwqT0A6z1rU",
    "metadata": {},
    "serial_number": "d16958e2-34f5-45cd-a488-16a5a1890be4",
    "status": "online"
  },
  {
    "id": "tmr_FTRAmQ5WhxBUeB",
    "object": "terminal.reader",
    "action": null,
    "device_sw_version": "",
    "device_type": "simulated_wisepos_e",
    "ip_address": "0.0.0.0",
    "label": "Brentwood Reader 2",
    "last_seen_at": 1698213118640,
    "livemode": false,
    "location": "tml_E3mFoQNWXbHPkc",
    "metadata": {},
    "serial_number": "aa5f082f-e941-44a9-bfab-3c0afc57e031",
    "status": "online"
  },
  {
    "id": "tmr_FS8rcwVDK5vsNt",
    "object": "terminal.reader",
    "action": null,
    "device_sw_version": "",
    "device_type": "simulated_wisepos_e",
    "ip_address": "0.0.0.0",
    "label": "Bethesda Reader 2",
    "last_seen_at": 1698213118640,
    "livemode": false,
    "location": "tml_E3mGSgW31G6Kmy",
    "metadata": {},
    "serial_number": "6e552e9d-5231-4caf-b436-72b24a1e0e04",
    "status": "online"
  },
  {
    "id": "tmr_FS8rWwH51sy5tJ",
    "object": "terminal.reader",
    "action": null,
    "device_sw_version": "",
    "device_type": "simulated_wisepos_e",
    "ip_address": "0.0.0.0",
    "label": "Studio City Reader 3",
    "last_seen_at": 1698213118640,
    "livemode": false,
    "location": "tml_E3mFwqT0A6z1rU",
    "metadata": {},
    "serial_number": "8f3f8cad-a2ae-48ad-8996-29b577c4603d",
    "status": "online"
  },
  {
    "id": "tmr_FS8rUQkIwy0Gg2",
    "object": "terminal.reader",
    "action": null,
    "device_sw_version": "",
    "device_type": "simulated_wisepos_e",
    "ip_address": "0.0.0.0",
    "label": "Studio City Reader 2",
    "last_seen_at": 1698213118640,
    "livemode": false,
    "location": "tml_E3mFwqT0A6z1rU",
    "metadata": {},
    "serial_number": "6d356a63-084a-4c6a-b353-362e9558937a",
    "status": "online"
  },
  {
    "id": "tmr_FS8rSwCDkTUQgX",
    "object": "terminal.reader",
    "action": null,
    "device_sw_version": "",
    "device_type": "simulated_wisepos_e",
    "ip_address": "0.0.0.0",
    "label": "Studio City Reader 1",
    "last_seen_at": 1698213118640,
    "livemode": false,
    "location": "tml_E3mFwqT0A6z1rU",
    "metadata": {},
    "serial_number": "a18a07cc-242b-43f9-9476-6a39d820d62b",
    "status": "online"
  },
  {
    "id": "tmr_FS8rKAOSfnp5kt",
    "object": "terminal.reader",
    "action": null,
    "device_sw_version": "",
    "device_type": "simulated_wisepos_e",
    "ip_address": "0.0.0.0",
    "label": "Brentwood Reader 1",
    "last_seen_at": 1698213118640,
    "livemode": false,
    "location": "tml_E3mFoQNWXbHPkc",
    "metadata": {},
    "serial_number": "ad5fb917-7203-4fdc-be4c-2c9cff70fd6b",
    "status": "online"
  },
  {
    "id": "tmr_FS8rEQHTlowvBn",
    "object": "terminal.reader",
    "action": null,
    "device_sw_version": "",
    "device_type": "simulated_wisepos_e",
    "ip_address": "0.0.0.0",
    "label": "Bethesda Reader 1",
    "last_seen_at": 1698213118640,
    "livemode": false,
    "location": "tml_E3mGSgW31G6Kmy",
    "metadata": {},
    "serial_number": "7f56e170-b0f4-4d49-aaf9-3019d99cd363",
    "status": "online"
  }
];