import _ from 'lodash';
import history from './history';
import { authKey, getToken, login } from './services/auth';

const authProvider = {
  login: ({ username, password }) =>
    login({ email: username, password, source: 'admin' })
      .then(data => {
        const sessionToken = data.token;
        const rest = data.user;
        localStorage.setItem(authKey, sessionToken, { expires: 90 });
        localStorage.setItem('user', JSON.stringify(rest));
        localStorage.setItem('role', rest.role);
        const sortedLocations = _.sortBy(
          rest.userLocation,
          ['location.name'],
          ['asc'],
        );
        localStorage.setItem(
          'userWorkLocations',
          JSON.stringify(sortedLocations),
        );
        const Activelocation = sortedLocations?.filter(
          loc => !loc.location.launch_status,
        );
        localStorage.setItem(
          'selectedLocation',
          JSON.stringify(Activelocation[0] ? Activelocation[0]?.location_id : sortedLocations[0]?.location_id),
        );
        if (rest.force_password_reset) {
          localStorage.setItem('force_password_reset', true);
          history.push('/profile');
          return;
        }
        history.push(['super-admin', 'admin'].includes(rest.role) ? '/guest' : 'appointment');
      })
      .catch(err => Promise.reject(err)),

  logout: () => {
    localStorage.clear();
    return Promise.resolve();
  },

  checkAuth: () => {
    if (getToken()) {
      return Promise.resolve();
    }

    return Promise.reject();
  },

  checkError: error => {
    const status = error && error.statusCode;
    if (status === 401 || status === 403) {
      localStorage.clear();
      sessionStorage.clear();
      window && window.location && window.location.replace('/#/login');
      return Promise.reject(error);
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },

  getIdentity: () => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      if (_.isEmpty(user)) {
        return Promise.reject();
      }

      const { id, first_name, last_name } = user;
      return Promise.resolve({ id, fullName: `${first_name} ${last_name}` });
    } catch (error) {
      return Promise.reject(error);
    }
  },

  getPermissions: () => {
    const role = localStorage.getItem('role');
    return role ? Promise.resolve(role) : Promise.reject();
  },
};

export default authProvider;
