import CloseIcon from '@material-ui/icons/Close';
import { keyBy } from 'lodash';
import React from 'react';
import {
  AutocompleteInput,
  Datagrid,
  FunctionField,
  ListContextProvider,
  Loading,
  Pagination,
  required,
  SaveButton,
  SimpleForm,
  Toolbar,
  useQuery
} from 'react-admin';
import Modal from 'react-modal';
// import { GUESTS } from '../utils/constants';
import { useDebouncedCallback } from 'use-debounce';
import { shipGift } from '../gift/gift-queries';
import { getFormattedDate, showToast } from '../utils';

export const GiftShipQueue = ({ ...props }) => {
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [perPage, setPerPage] = React.useState(10);
  const [giftCode, setGiftCode] = React.useState('');
  const [giftDetails, setGiftDetails] = React.useState(null);
  const validateRequired = [required()];

  const [shipPopup, setShipPopup] = React.useState({
    isOpen: false,
    giftCardId: null,
  });
  const [confirmShipPopup, setConfirmShipPopup] = React.useState({
    isOpen: false,
    giftCardId: null,
  });
  const [addressPopup, setAddressPopup] = React.useState({
    isOpen: false,
    giftCardId: null,
  });
  const [searchFilter, setSearchFilter] = React.useState('');

  const handleSearch = useDebouncedCallback(term => {
    setSearchFilter(term);
  }, 300);

  const onShip = (giftId, params) => {
    setLoading(true);
    shipGift(giftId, params)
      .then(resp => {
        setLoading(false);
        window.location.reload();
      })
      .catch(err => {
        setLoading(false);
        console.log(err, 'error in shipping');
        showToast('Error in shipping', 'error');
      });
  };

  const closeShipPopupModal = () => {
    setShipPopup({ ...shipPopup, isOpen: false });
  };

  const closeConfirmShipPopupModal = () => {
    setConfirmShipPopup({ ...confirmShipPopup, isOpen: false });
  };

  const closeAddressConfirmationModal = () => {
    setAddressPopup({ ...addressPopup, isOpen: false });
  };

  const { data: giftCodes, refetch } = useQuery({
    type: 'getList',
    resource: 'gift',
    payload: {
      filter: { status: 'unpaid', giftcode: searchFilter },
      pagination: {
        perPage: 25,
        page: 1,
      },
    },
  });

  const getGiftCardCodes = () => {
    return searchFilter && giftCodes && giftCodes.length > 0
      ? giftCodes?.map(gC => ({
          id: gC.gift_code,
          name: gC.gift_code,
        }))
      : [];
  };

  const confirmShipPopupModal = () => {
    const header = 'Are you sure?';
    const confirmButtonText = 'Yes, Proceed';
    const cancelButtonText = 'No, Choose Another';
    const bodyContent =
      'Please confirm that this code matches your card. You will NOT be able to reassign or unassign it after choosing it.';
    return (
      <Modal isOpen={confirmShipPopup.isOpen}>
        <div className="modal-header">
          <div className="light-text">{header}</div>
          <CloseIcon
            onClick={() => {
              setGiftCode('');
              closeConfirmShipPopupModal();
            }}
            className="close-button"
          />
        </div>
        <div className="btn-padding">{bodyContent}</div>
        <div className="btn-padding text-4xl font-bold">{giftCode}</div>
        <div className="btn-padding mt-5">
          <button
            className="button-blue mr-4"
            onClick={() => {
              closeConfirmShipPopupModal();
              setAddressPopup({ ...addressPopup, isOpen: true });
            }}
          >
            {confirmButtonText}
          </button>
          <button
            className="secondary-btn"
            onClick={() => {
              closeConfirmShipPopupModal();
              setShipPopup({ ...shipPopup, isOpen: true });
            }}
          >
            {cancelButtonText}
          </button>
        </div>
      </Modal>
    );
  };

  const handleSave = values => {
    closeShipPopupModal();
    setGiftCode(values.code);
    setConfirmShipPopup({
      giftCardId: shipPopup.giftCardId,
      isOpen: true,
    });
  };

  const GiftToolbar = props => (
    <Toolbar {...props}>
      <SaveButton label="Confirm" onSave={handleSave} />
    </Toolbar>
  );

  const shipPopupModal = () => {
    const header = 'Confirm Ship';
    const bodyContent = 'Enter the Giftcode below';
    return (
      <Modal isOpen={shipPopup.isOpen}>
        <div className="modal-header">
          <div className="light-text">{header}</div>
          <CloseIcon
            onClick={() => {
              closeShipPopupModal();
              setGiftCode('');
            }}
            className="close-button"
          />
        </div>
        <div className="btn-padding">{bodyContent}</div>
        <SimpleForm toolbar={<GiftToolbar />}>
          <AutocompleteInput
            source="code"
            label="Gift Code"
            fullWidth={true}
            validate={validateRequired}
            choices={getGiftCardCodes()}
            onInputValueChange={value => {
              handleSearch(value);
            }}
            resettable
          />
        </SimpleForm>
      </Modal>
    );
  };

  const addressConfirmationModal = () => {
    if (giftDetails) {
      const header = 'Gift Card Shipment Confirmation';
      const confirmButtonText = 'Done';
      const bodyContent =
        'Gift card code has been assigned to this gift card purchase. Please send the card to the recipient using the address shown below.';
      const {
        recipient_name,
        message,
        city,
        country,
        state,
        street1,
        zip,
        giftcarddeliveries: {
          gift_value,
          entity_type,
          stripe_plan_id,
          service,
          membership_length,
        },
      } = giftDetails;
      const address = `${street1 ? street1 : ''} ${city ? city : ''} ${
        state ? state : ''
      } ${country ? country : ''} ${zip ? zip : ''}`;
      return (
        <Modal isOpen={giftDetails && addressPopup.isOpen}>
          <div className="modal-header">
            <div className="light-text">{header}</div>
            <CloseIcon
              onClick={() => {
                setGiftCode('');
                closeAddressConfirmationModal();
                setGiftDetails(null);
              }}
              className="close-button"
            />
          </div>
          <div className="btn-padding">{bodyContent}</div>
          <div className="details-display">
            <div className="label">Gift Card Code: </div>
            <div>{giftCode}</div>
            <div className="label">Recipient Name: </div>
            <div>{recipient_name}</div>
            <div className="label">Recipient Address: </div>
            <div>{address}</div>
            <div className="label">Gift Message: </div>
            <div>{message}</div>
            <div className="label">Gift Type: </div>
            <div>
              {entity_type === 'appointment'
                ? `${service?.duration} min Massage`
                : entity_type === 'membership'
                ? `${membership_length}-months ${stripe_plan_id} Membership`
                : entity_type}
            </div>
            {gift_value && (
              <>
                <div className="label">Gift Card Value: </div>
                <div>{gift_value}</div>
              </>
            )}
          </div>
          <div className="details-display btn-padding">
            <div>
              <button
                className="button-blue"
                onClick={() => {
                  closeAddressConfirmationModal();
                  onShip(giftDetails.id, { code: giftCode });
                  setGiftDetails(null);
                }}
              >
                {confirmButtonText}
              </button>
            </div>
          </div>
        </Modal>
      );
    }
  };

  const {
    data,
    total,
    loading: shippingQueueLoading,
  } = useQuery({
    type: 'getShipList',
    resource: 'gift',
    payload: { pagination: { page, perPage }, filters: {} },
  });

  if (shippingQueueLoading) return <Loading />;

  const PostPagination = props => (
    <Pagination rowsPerPageOptions={[10, 25]} {...props} />
  );

  return (
    <>
      {shipPopupModal()}
      {confirmShipPopupModal()}
      {addressConfirmationModal()}
      <ListContextProvider
        value={{
          resource: 'gift',
          basePath: `/gift/ship-queue`,
          data: keyBy(data, 'id'),
          ids: data && data.map(({ id }) => id),
          currentSort: { field: 'id', order: 'ASC' },
          selectedIds: [],
          total,
          page,
          perPage,
          setPerPage,
          setPage,
        }}
        // pagination={<PostPagination />}
        perPage={perPage}
      >
        <Datagrid>
          <FunctionField
            label="Purchased On"
            render={record =>
              record?.giftcarddeliveries?.paid_at
                ? getFormattedDate(record?.giftcarddeliveries?.paid_at)
                : ''
            }
          />
          <FunctionField
            label="Name"
            render={record =>
              record.sender_name
            }
          />
          <FunctionField
            label="Email"
            render={record => (record?.sender ? `${record?.sender.email}` : '')}
          />
          <FunctionField
            label="Recipient Name"
            render={record => `${record?.recipient_name}`}
          />
          <FunctionField
            label="State"
            render={record => `${record?.giftcarddeliveries.status}`}
          />
          <FunctionField
            render={record => {
              return (
                <button
                  className="button-blue"
                  onClick={() => {
                    setShipPopup({ isOpen: true, giftCardId: record.id });
                    setGiftDetails(record);
                  }}
                >
                  <div className="has-flex">
                    {shipPopup.giftCardId === record.id && loading && (
                      <div class="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-4 w-4 mr-3"></div>
                    )}
                    {shipPopup.giftCardId === record.id && loading
                      ? 'Shipping'
                      : 'Ship'}
                  </div>
                </button>
              );
            }}
          />
        </Datagrid>
        <PostPagination
          page={page}
          perPage={perPage}
          setPage={setPage}
          total={total}
        />
      </ListContextProvider>
    </>
  );
};
