import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import {
  AutocompleteInput,
  Datagrid,
  Filter,
  FunctionField,
  List,
  minValue,
  NumberInput,
  required,
  SaveButton,
  SearchInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useNotify,
  useQuery,
  useRefresh,
} from 'react-admin';
import Modal from 'react-modal';
import { useDebouncedCallback } from 'use-debounce';
import { customDataProvider } from '../data-provider';
import history from '../history';
import {
  getFormattedDate_T,
  getLocationUrlName,
  getLocations,
  isAdmin,
  isGss,
  isSuperAdmin,
  mapLocationReaderId,
  showToast,
} from '../utils';
import {
  giftTypeChoice,
  membershipDurationChoice,
  membershipTypeChoice,
} from '../utils/constants';
import styles from './gift.module.css';

import useStripeTerminal from './stripe';
import { isEmpty } from 'lodash';
import config from '../app.config';
import { SIM_READERS } from './reader-list';
const USE_SIMULATOR =
  process.env.REACT_APP_NODE_ENV === 'production' ? false : true;

const GiftFilter = props => (
  <Filter {...props} className="searchWrapper">
    <SearchInput source="sender_name" placeholder="Purchaser Name" alwaysOn />
    <SearchInput source="sender_email" placeholder="Purchaser Email" alwaysOn />
    <SearchInput
      source="recipient_name"
      placeholder="Recipient Name"
      alwaysOn
    />
    <SearchInput source="giftcode" placeholder="Gift Code" alwaysOn />
    {/* <SelectInput
      className="select-no-label"
      label="Status"
      source="gift_status"
      alwaysOn
      choices={statusOptions}
    /> */}
  </Filter>
);

export const GiftList = ({ permissions, ...props }) => {
  const [showModal, setShowModal] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isPurchasingGift, setIsPurchasingGift] = useState(false);
  const [searchFilter, setSearchFilter] = useState('');
  const [ccTransactionChecked, setCcTransactionChecked] = useState(true);

  const notify = useNotify();
  const refresh = useRefresh();
  const validateRequired = [required()];

  const [isMembership, setIsMembership] = useState(false);
  const [membershipDuration, setMembershipDuration] = useState();
  const [membershipType, setMembershipType] = useState();
  const [totalGiftvalue, setTotalGiftValue] = useState(0);
  const [isPaying, setIsPaying] = useState(false);

  const [showSelectReader, setShowSelectReader] = useState(false);
  const [readersList, setReadersList] = useState([]);
  const [selectedReader, setSelectedReader] = useState({});

  /**
   * Calculate total membership gift value
   */
  const getTotalMembersipGiftValue = () => {
    try {
      const duration = parseInt(membershipDuration);
      const type = parseInt(membershipType);
      let total = 0;

      if (!duration || !type) {
        return;
      }

      if (
        (duration !== undefined || !isNaN(duration)) &&
        (type !== undefined || !isNaN(type))
      ) {
        switch (type) {
          case 50:
            total = 95 * duration;
            break;
          case 80:
            total = 125 * duration;
            break;
          default:
            showToast('Please select the Memerbship type again!', 'error');
            break;
        }
        setTotalGiftValue(total);
        return total;
      } else {
        showToast(
          "Something's wrong, please select the Memerbship plan & duration again!",
          'error',
        );
      }
    } catch (err) {
      showToast(err.message, 'error');
    }
  };

  const handleSearch = useDebouncedCallback(term => {
    setSearchFilter(term);
  }, 300);

  const workLocations = JSON.parse(localStorage.getItem('userWorkLocations'));

  const { data: giftCodes, refetch } = useQuery({
    type: 'getList',
    resource: 'gift',
    payload: {
      filter: { status: 'unpaid', giftcode: searchFilter },
      pagination: {
        perPage: 25,
        page: 1,
      },
    },
  });

  useEffect(() => {
    if (document) {
      const _checkboxEl = document.getElementById('giftTransaction');
      if (_checkboxEl) _checkboxEl.checked = true;
    }
  }, []);

  useEffect(() => {
    searchFilter && refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilter]);

  const {
    discoveringReaders,
    connectingToReader,
    collectingPayment,

    discoverReaders,
    disconnectReader,
    connectToReader,
    connectToSimulator,
    collectCardPayment,
  } = useStripeTerminal();

  // const filters = { "status.nil": 1 };
  // const { data: giftCards } = useQuery({
  //   type: 'getList',
  //   resource: 'gift',
  //   payload: { filters },
  // });

  // const modifiedGifts = giftCards && giftCards.map(g => ({ ...g, name: g.gift_code }));

  const onRowClick = (id, basePath, record) => {
    console.log(id, basePath, record, 'onRowClick');
    return `/gift/view-gift-card-details/${id}`;
  };

  const handleSave = form => {
    setShowModal(false);
    setShowConfirmModal(true);
    setFormValues(form);
    if (Number(form?.charge_amount) === 0) {
      setCcTransactionChecked(false);
    }
    handleSetDefaultReader(form?.location);
  };

  if (typeof window !== 'undefined') {
    Modal.setAppElement('body');
  }

  /**
   * Calculate the membership gift value
   * @param {*} membershipLength
   * @param {*} membershipType
   * @returns
   */
  const calculateMembershipValue = (membershipLength, membershipType) => {
    const length = parseInt(membershipLength);
    const type = parseInt(membershipType);
    let amount = 0;
    switch (type) {
      case 50:
        amount = 95 * length;
        break;
      case 80:
        amount = 125 * length;
        break;
      default:
        break;
    }
    return amount;
  };

  const purchaseGift = async () => {
    let _chargeAmount = '';
    if (isAdmin(permissions) || isSuperAdmin(permissions)) {
      _chargeAmount = formValues.charge_amount;
    } else {
      if (formValues.gift_card_type === 'points') {
        _chargeAmount = formValues.amount;
      } else {
        _chargeAmount = calculateMembershipValue(
          formValues.membership_length,
          formValues.membership_type,
        );
      }
    }
    const transactionType = ccTransactionChecked ? 'card' : 'cash';
    try {
      let paymentResult;
      if (transactionType === 'card') {
        if (USE_SIMULATOR) {
          console.log('Making payment on a SIM reader...');
          await connectToSimulator();
        } else {
          if (isEmpty(selectedReader)) {
            notify(
              'Reader data not initialized, please select a reader again!',
            );
            return;
          }
          console.log('Making payment on a LIVE reader...');
          await connectToReader(selectedReader);
        }
        console.log('Reader connection secured!');
        paymentResult = await collectCardPayment(
          _chargeAmount,
          formValues?.location,
        );
        console.log('Payment result', paymentResult);
      }

      // Construct payload depending upon the 'gift type'
      let payload = {};
      if (formValues.gift_card_type === 'points') {
        payload = {
          payment_method: transactionType,
          code: formValues.code,
          email: formValues.email,
          first_name: formValues.firstName,
          last_name: formValues.lastName,
          points: formValues.amount,
          gift_card_type: formValues.gift_card_type,
          membership_length: formValues.membership_length,
          stripe_plan_id: formValues.membership_type,
          location_id: formValues.location,
          charge_amount:
            isSuperAdmin(permissions) || isAdmin(permissions)
              ? formValues.charge_amount
              : formValues.amount,
        };
      } else {
        let amount = calculateMembershipValue(
          formValues.membership_length,
          formValues.membership_type,
        );
        payload = {
          payment_method: transactionType,
          code: formValues.code,
          email: formValues.email,
          first_name: formValues.firstName,
          last_name: formValues.lastName,
          // points: amount,
          gift_card_type: formValues.gift_card_type,
          membership_length: formValues.membership_length,
          stripe_plan_id: formValues.membership_type,
          location_id: formValues.location,
          charge_amount:
            isSuperAdmin(permissions) || isAdmin(permissions)
              ? formValues.charge_amount
              : formValues.amount
              ? formValues.amount
              : amount,
        };
      }

      if (transactionType === 'card' && paymentResult) {
        payload.payment_intent_id = paymentResult.paymentIntent.id;
      }
      setIsPurchasingGift(true);
      return customDataProvider.create('gift', { payload }).then(
        () => {
          setIsPaying(false);
          setCcTransactionChecked(true);
          setShowConfirmModal(false);
          setIsPurchasingGift(false);
          disconnectReader();
          notify('Purchased successfully');
          setIsMembership(false);
          setMembershipDuration(0);
          setMembershipType('');
          clearReaderData();
          refresh();
        },
        err => {
          setIsPaying(false);
          setIsPurchasingGift(false);
          disconnectReader();
          setShowConfirmModal(false);
          notify(err.response ? err.response?.data?.message : err.message);
        },
      );
    } catch (e) {
      setIsPaying(false);
      setIsPurchasingGift(false);
      setShowConfirmModal(false);
      alert(JSON.stringify(e));
      console.log('** FAILED TO MAKE PAYMENT', e);
    }
  };

  const handleSetDefaultReader = async locationId => {
    const urlName = getLocationUrlName(locationId);
    const readerLocationId = mapLocationReaderId(urlName);
    const defaultReadersList = !USE_SIMULATOR
      ? await discoverReaders(USE_SIMULATOR, readerLocationId)
      : filterFromSimReadersDummyList(SIM_READERS, readerLocationId);
    console.log('** Default list of readers', defaultReadersList);
    setReadersList(defaultReadersList);
    if (!isEmpty(defaultReadersList)) {
      const defaultReader = defaultReadersList?.find(
        reader => reader.id === config[`${urlName}DefaultReader`],
      );
      console.log('** The default selected reader is', defaultReader);
      setSelectedReader(defaultReader);
    }
  };

  const filterFromSimReadersDummyList = (readers, locationId) =>
    readers.filter(reader => reader.location === locationId);

  const handleReaderSelect = readerId => {
    if (isEmpty(readersList)) {
      notify("Couldn't fetch readers, please refresh the page & try again!");
      return;
    }
    const chosenReader = readersList?.find(reader => reader.id === readerId);
    console.log('** You chose this reader', chosenReader);
    setSelectedReader(chosenReader);
  };

  const renderReaderOptions = () => {
    return (
      <select
        className={styles['select-reader']}
        onChange={e => handleReaderSelect(e.target.value)}
      >
        <option
          id={selectedReader?.id}
          location={selectedReader?.location}
          value={selectedReader?.id}
        >
          {selectedReader?.label}
        </option>
        {readersList?.map(
          reader =>
            reader.status === 'online' &&
            reader.id !== selectedReader?.id && (
              <option
                id={reader.id}
                location={reader.location}
                value={reader.id}
              >
                {reader.label}
              </option>
            ),
        )}
      </select>
    );
  };

  const clearReaderData = () => {
    setShowSelectReader(false);
    setReadersList([]);
    setSelectedReader({});
  };

  const confirmModal = () => {
    if (isPaying) {
      return (
        <Modal isOpen={isPaying}>
          <div className="flex flex-col justify-center items-center">
            <div className={styles.loader}></div>
            <div>Making payment, please wait...</div>
          </div>
        </Modal>
      );
    }
    return (
      <Modal isOpen={showConfirmModal}>
        <div className="modal-header">
          <div className="light-text">Confirm to make payment</div>
          <CloseIcon
            onClick={() => {
              setShowConfirmModal(false);
              clearReaderData();
            }}
            className="close-button"
          />
        </div>
        <div className="btn-padding">
          <div>
            Please confirm that this code matches your card. You will NOT be
            able to reassign or unassign it after choosing it.
          </div>
          <br />
          <div className="text-4xl font-bold">{formValues?.code}</div>
          <br />
          <div>
            <input
              type="checkbox"
              id="giftTransaction"
              name="transaction"
              value="card"
              checked={ccTransactionChecked}
              disabled={
                !(isSuperAdmin(permissions) || isAdmin(permissions)) ||
                Number(formValues?.charge_amount === 0)
              }
              onClick={e => setCcTransactionChecked(e.target.checked)}
            />
            <label for="giftTransaction">
              {' '}
              Process a credit card transaction
            </label>
          </div>
          <div className={styles['select-reader-input-wrapper']}>
            <div id="selectReader-wrapper">
              <input
                type="checkbox"
                id="selectReader"
                name="transaction"
                value="card"
                checked={showSelectReader}
                disabled={Number(formValues?.charge_amount === 0)}
                onClick={() => setShowSelectReader(state => !state)}
              />
              <label for="selectReader"> Select a specific reader</label>
            </div>
            {showSelectReader &&
              !Number(formValues?.charge_amount === 0) &&
              renderReaderOptions()}
          </div>
          {Number(formValues?.charge_amount === 0) ? (
            <div className="pt-3 text-gray-600">
              <div>
                <span className="font-bold">NOTE</span>: Charge amount is $0,
                hence:
              </div>
              <ul className="list-disc px-5">
                <li>Reader selection is disabled.</li>
                <li>No credit card transaction will be processed.</li>
              </ul>
            </div>
          ) : null}
        </div>
        <div className="details-display btn-padding">
          <div>
            <button
              className="secondary-btn"
              onClick={() => {
                setShowConfirmModal(false);
                setShowModal(true);
                clearReaderData();
              }}
              disabled={
                discoveringReaders ||
                connectingToReader ||
                collectingPayment ||
                isPurchasingGift
              }
            >
              Cancel
            </button>
          </div>
          <div align="right">
            <button
              className="button-blue"
              disabled={
                discoveringReaders ||
                connectingToReader ||
                collectingPayment ||
                isPurchasingGift
              }
              onClick={() => {
                setIsPaying(true);
                purchaseGift();
              }}
            >
              Make Payment
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  const GiftToolbar = props => (
    <Toolbar {...props}>
      <SaveButton label="Purchase" onSave={handleSave} />
    </Toolbar>
  );

  const getGiftCardCodes = () => {
    return searchFilter && giftCodes && giftCodes.length > 0
      ? giftCodes?.map(gC => ({
          id: gC.gift_code,
          name: gC.gift_code,
        }))
      : [];
  };

  useEffect(() => {
    getTotalMembersipGiftValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membershipDuration, membershipType]);

  /**
   * Check if gift type is a 'membership'
   * @param {*} event
   */
  const getGiftType = event => {
    if (event.target.value === 'membership') {
      setIsMembership(true);
    } else {
      setIsMembership(false);
    }
  };

  const giftModal = () => (
    <Modal isOpen={showModal}>
      <div className="modal-header">
        <div className="light-text">Sell a Gift Card in Shop</div>
        <CloseIcon
          onClick={() => setShowModal(false)}
          className="close-button"
        />
      </div>
      <div className="btn-padding">
        <SimpleForm
          style={{ padding: '0px' }}
          initialValues={{ location: 1 }}
          toolbar={<GiftToolbar />}
        >
          <SelectInput
            source="location"
            optionText="name"
            choices={getLocations(workLocations)}
            label="Location"
            style={{ width: '100%' }}
            validate={validateRequired}
          />
          <AutocompleteInput
            source="code"
            label="Gift Code"
            fullWidth={true}
            validate={validateRequired}
            choices={getGiftCardCodes()}
            onInputValueChange={value => {
              handleSearch(value);
            }}
            resettable
          />
          <TextInput
            source="firstName"
            label="Purchaser First Name"
            style={{ width: '100%' }}
            validate={validateRequired}
          />
          <TextInput
            source="lastName"
            label="Purchaser Last Name"
            style={{ width: '100%' }}
            validate={validateRequired}
          />
          <TextInput
            source="email"
            label="Purchaser Email"
            style={{ width: '100%' }}
            validate={validateRequired}
          />
          <SelectInput
            label="Select a Gift Type"
            style={{ width: '100%' }}
            source="gift_card_type"
            alwaysOn
            choices={giftTypeChoice}
            onChange={getGiftType}
            validate={validateRequired}
          />
          {isMembership ? (
            <>
              <SelectInput
                label="Membership Duration"
                source="membership_length"
                style={{ width: '100%' }}
                choices={membershipDurationChoice}
                validate={validateRequired}
                onChange={e => setMembershipDuration(e.target.value)}
              />
              {membershipDuration ? (
                <SelectInput
                  label="Membership Type"
                  source="membership_type"
                  style={{ width: '100%' }}
                  choices={membershipTypeChoice}
                  validate={validateRequired}
                  onChange={e => setMembershipType(e.target.value)}
                />
              ) : null}
            </>
          ) : null}
          {membershipDuration && membershipType && isMembership ? (
            <div className="text-gray-500 text-sm mb-3">
              Total Gift Value: ${totalGiftvalue}
            </div>
          ) : (
            <NumberInput
              source="amount"
              label="Gift Card Value"
              style={{ width: '100%' }}
              validate={[
                required(),
                minValue(1, 'The least card value value is $1'),
              ]}
            />
          )}
          {(isSuperAdmin(permissions) || isAdmin(permissions)) && (
            <NumberInput
              source="charge_amount"
              label="Charge Amount"
              style={{ width: '100%' }}
              validate={[required(), minValue(0)]}
            />
          )}
        </SimpleForm>
      </div>
    </Modal>
  );

  const getDeliveryType = record => {
    const deliveryInfo =
      record && record.giftcarddeliveries && record.giftcarddeliveries[0];
    return deliveryInfo.delivery_mode ? deliveryInfo.delivery_mode : '';
  };

  return (
    <>
      {showModal && giftModal()}
      {showConfirmModal && confirmModal()}
      <div className="text-right">
        {(isSuperAdmin(permissions) ||
          isAdmin(permissions) ||
          isGss(permissions)) && (
          <button
            className="button-blue mr-2"
            onClick={() => history.push(`/gift/ship-queue`)}
          >
            Shipping Queue
          </button>
        )}
        <button className="button-blue" onClick={() => setShowModal(true)}>
          Sell a Gift Card in Shop
        </button>
      </div>
      <List
        filters={<GiftFilter />}
        {...props}
        perPage={10}
        bulkActionButtons={false}
        className="main-table"
        exporter={false}
        empty={<GiftFilter />}
      >
        <Datagrid rowClick={onRowClick}>
          <TextField source="gift_code" label="Gift Card Code" />
          <FunctionField
            label="Purchase Date"
            render={record => getFormattedDate_T(record?.paid_at)}
          />
          <FunctionField
            label="Purchaser Name"
            render={record =>
              record?.giftcarddeliveries?.[0]
                ? record?.giftcarddeliveries?.[0].sender_name
                : ''
            }
          />
          <FunctionField
            label="Purchaser Email"
            render={record =>
              record?.giftcarddeliveries?.[0]
                ? record?.giftcarddeliveries?.[0].sender_email
                : ''
            }
          />
          <FunctionField
            label="Recipient Name"
            render={record =>
              record?.giftcarddeliveries?.[0]
                ? record?.giftcarddeliveries?.[0].recipient_name
                : ''
            }
          />
          <FunctionField
            label="Delivery Type"
            render={record => getDeliveryType(record)}
          />
          <FunctionField
            label="Status"
            render={record => record?.gift_status}
          />
        </Datagrid>
      </List>
    </>
  );
};
